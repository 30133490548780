<template>
  <div class="container-fluid">
    <div class="row text-center">
      <div class="col-lg-6 col-md-6 col-sm-12 flexbox"> 
        <h1 class="flex-item">{{ $t('contact.header')}}</h1>
        <p class="flex-item">{{ $t('contact.text1')}}</p>
        <p class="flex-item">{{ $t('contact.email')}}sjavarsetrid@sjavarsetrid.is{{ $t('contact.phone')}}</p>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        <img src="../assets/logo-black.png" alt="" class="contact-img">
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-12">

      </div>
      <div class="col-lg-6 col-md-6 col-sm-12">
        
      </div>
    </div>
  </div>
</template>


<style scoped>
* {
  background-color: #262526;
}
.flexbox {
  display: flex;
  flex-direction: column;
  justify-content:  space-evenly;
  margin-top: 100px;
  margin-bottom: 60px;
  padding: 40px;
  color: #ffffff;
}
.flexbox h1 {
  margin-bottom: 40px;
}
.flex-item {
  margin-left: 60px;
}
.contact-img {
  max-width: 70%;
  border-radius: 10px;
  margin-top: 180px;
  margin-bottom: 160px;
}
@media only screen and (max-width: 767px) { 
.flexbox {
  margin-top: 80px;
}
.flex-item {
  margin-right: 60px;
}
.contact-img {
  margin-top: 40px;
  margin-bottom: 100px;
}
}
</style>